import {
  Avatar,
  Box,
  Divider,
  Grid,
  Modal,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { FleetInfoModalContext } from "./FleetInfoModal";
import StatsCard from "@components/infoCards/statsCard";
import DatePicker, { DatePickerProps } from "@components/datePicker";
import { getContracts, getFleetsMetricsByID } from "@nexusAPI/fleet";
import TableLoader from "@components/tableLoader";
import IonTable from "@components/ionTable";
import { IonButton, IonButtonMultiVariant } from "@components/ionButton";
import { ReactComponent as EditIcon } from "@assets/icons/pencil-icon.svg";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import ContractModal from "../contractModal";
import IonageTooltip from "@components/ionTooltip";
import { ReactComponent as EyeOpen } from "@assets/materialIcons/visibility.svg";
import StationCountTable from "./StationCountTable";
import { canUpdateFleet } from "@utils/authService";

const CONTRACT_LIST_LIMIT = 50;

const getStatusInfo = (palette: Theme["palette"], status?: string) => {
  let data: {
    chipColor: string;
    chipBackground: string;
    chipLabel: string;
  };
  switch (status) {
    case "ACTIVE":
      data = {
        chipColor: palette.success.dark,
        chipBackground: palette.success.main,
        chipLabel: "Active",
      };
      break;
    case "INACTIVE":
      data = {
        chipColor: palette.error.dark,
        chipBackground: palette.error.main,
        chipLabel: "Inactive",
      };
      break;
    case "PENDING":
      data = {
        chipColor: palette.warning.dark,
        chipBackground: palette.warning.main,
        chipLabel: "Pending",
      };
      break;
    case "REJECTED":
      data = {
        chipColor: palette.grey[700],
        chipBackground: palette.grey[500],
        chipLabel: "Rejected",
      };
      break;
    default:
      data = {
        chipColor: palette.grey[700],
        chipBackground: palette.grey[500],
        chipLabel: "Unknown",
      };
      break;
  }
  return data;
};

export const FleetPane = () => {
  const theme = useTheme();
  const { fleetId, fleetData, handleUpdate } = useContext(
    FleetInfoModalContext
  );
  const [fleetMetricsLoading, setFleetMetricsLoading] = useState<boolean>(true);
  const [contractListLoading, setContractListLoading] = useState<boolean>(true);
  // const [scrolledIndex, setScrolledIndex] = useState<number>(0);
  const [contractModalOpen, setContractModalOpen] = useState<boolean>(false);
  const [contractModalData, setContractModalData] = useState<{
    contractId?: number;
    fleetId?: number;
  }>({});
  const searchCloseRef = useRef<SVGSVGElement>(null);

  const [fleetMetricsData, setFleetsMetricsData] =
    useState<Awaited<ReturnType<typeof getFleetsMetricsByID>>>();
  const [contractListData, setContractListData] = useState<
    Awaited<ReturnType<typeof getContracts>>["data"]
  >([]);
  const [dateSelection, setDateSelection] =
    useState<DatePickerProps["dateSelection"]>();
  const scrollParentRef = useRef<HTMLDivElement>(null);

  const getFleetMetricsData = useCallback(
    async (filters: Parameters<typeof getFleetsMetricsByID>[0]) => {
      try {
        setFleetMetricsLoading(true);
        const response = await getFleetsMetricsByID(filters);
        setFleetsMetricsData(response);
      } catch {
      } finally {
        setFleetMetricsLoading(false);
      }
    },
    []
  );

  const getContractListData = useCallback(
    async (filters: Parameters<typeof getContracts>[0]) => {
      try {
        setContractListLoading(true);
        const response = await getContracts(filters);
        const { data = [] } = response || {};
        setContractListData((prevData) => [...prevData, ...data]);
      } catch {
      } finally {
        setContractListLoading(false);
      }
    },
    []
  );

  const resetTable = useCallback(() => {
    setContractListData([]);
  }, []);

  const handleCloseModal =
    (fn: Function) =>
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        fn();
      }
    };

  const handleContractModalClose = () => {
    setContractModalOpen(false);
    setContractModalData({});
  };

  const onContractAddCallback = () => {
    resetTable();
    if (searchCloseRef.current) {
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      searchCloseRef.current.dispatchEvent(clickEvt);
    } else {
      getContractListData({
        fleetId,
        limit: CONTRACT_LIST_LIMIT,
        skip: 0,
      });
      const { endDate, startDate } = dateSelection || {};
      const currentFilters = {
        dateFrom: startDate?.toISOString(),
        dateTo: endDate?.toISOString(),
      };
      if (startDate && endDate && fleetId) {
        getFleetMetricsData({ ...currentFilters, fleetId });
      }
    }
    handleUpdate && handleUpdate(true);
    handleContractModalClose();
  };

  useEffect(() => {
    const { endDate, startDate } = dateSelection || {};
    const currentFilters = {
      dateFrom: startDate?.toISOString(),
      dateTo: endDate?.toISOString(),
    };
    if (startDate && endDate && fleetId) {
      getFleetMetricsData({ ...currentFilters, fleetId });
    }
  }, [getFleetMetricsData, dateSelection, fleetId]);

  useEffect(() => {
    const debounceCall = setTimeout(() => {
      getContractListData &&
        getContractListData({
          fleetId,
          limit: CONTRACT_LIST_LIMIT,
          // skip: scrolledIndex,
        });
    }, 500);
    return () => clearTimeout(debounceCall);
  }, [fleetId, getContractListData]);

  const {
    operational_regions = [],
    category = "",
    updated_at,
    email = "",
    logo_url = "",
    name = "",
    operational_size = "",
    phone_number = "",
    vehicle_types = [],
  } = fleetData || {};

  return (
    <Box sx={{ display: "flex", flex: 1, mt: 2, flexDirection: "column" }}>
      <Box
        sx={{
          mx: 2,
          mt: 2,
          display: "flex",
        }}
      >
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                p: 2,
                background: theme.palette.background.card,
                color: theme.palette.getContrastText(
                  theme.palette.background.card
                ),
                borderRadius: 2,
                boxShadow: theme.shadows[1],
                minHeight: 132,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography fontWeight={"500"} fontSize={20}>
                  General Information
                </Typography>
                <Typography fontSize={14}>
                  Last Updated At:{" "}
                  {updated_at
                    ? `${new Date(updated_at)?.toLocaleDateString("en-IN", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })} ${new Date(updated_at)?.toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`
                    : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={logo_url}
                  sx={{
                    height: 64,
                    width: 64,
                    mr: 1.5,
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                    {name}
                  </Typography>
                  <Typography>
                    {[
                      ...(phone_number ? [phone_number] : []),
                      ...(email ? [email] : []),
                    ].join(" | ")}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.grey[600] }}
                  >
                    Category
                  </Typography>
                  <Typography>{category}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.grey[600] }}
                  >
                    Operational Size
                  </Typography>
                  <Typography>{operational_size}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.grey[600] }}
                  >
                    Vehicle Types
                  </Typography>
                  <Typography>{vehicle_types.join(", ")}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.grey[600] }}
                  >
                    Operational Region
                  </Typography>
                  <Typography>{operational_regions.join(", ")}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box
            sx={{
              background: theme.palette.background.card,
              color: theme.palette.getContrastText(
                theme.palette.background.card
              ),
              borderRadius: 2,
              boxShadow: theme.shadows[1],
              flex: 1,
              mt: 2,
              ml: 2,
              p: 2,
            }}
          >
            <Grid container item spacing={2}>
              <Grid item xs={12} md={9}>
                <Typography fontWeight={"500"} fontSize={20}>
                  Metrics
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                justifyContent={"flex-end"}
              >
                <DatePicker
                  dateSelection={dateSelection}
                  setDateSelection={setDateSelection}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    label="Total Sessions"
                    unit="sessions"
                    valueType="general"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              fleetMetricsData?.total_sessions_count || 0,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    label="Energy Consumed"
                    valueType="kWh"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              Math.round(
                                ((fleetMetricsData?.total_energy_consumed_wh ||
                                  0) /
                                  1000) *
                                  1000
                              ) / 1000,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    prefix={"₹"}
                    label="Revenue Collected"
                    valueType="general"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              Math.round(
                                ((fleetMetricsData?.total_revenue_collected_paise ||
                                  0) /
                                  100) *
                                  100
                              ) / 100,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box
        sx={{
          flex: 1,
          m: 2,
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.card,
          color: theme.palette.getContrastText(theme.palette.background.card),
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: theme.shadows[1],
        }}
      >
        <Box
          sx={{
            py: 2,
            px: 2,
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Box sx={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>
              Contracts
            </Typography>
          </Box>

          {canUpdateFleet() && (
            <IonButton
              color={"button"}
              variant="contained"
              sx={{ ml: 2, whiteSpace: "nowrap" }}
              onClick={() => {
                setContractModalOpen(true);
              }}
              startIcon={
                <POIAdd fill={"currentColor"} height={24} width={24} />
              }
            >
              Create Contract
            </IonButton>
          )}
        </Box>
        <Divider sx={{ borderColor: theme.palette.grey[200] }} />
        <Box sx={{ flex: 1, position: "relative" }}>
          <div
            ref={scrollParentRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "auto",
            }}
          >
            <IonTable
              tableVirtuosoProps={{
                useWindowScroll: true,
                customScrollParent: scrollParentRef.current || undefined,
              }}
              tableProps={{
                stickyHeader: true,
                size: "small",
                sx: { tableLayout: "fixed" },
              }}
              columns={[
                { label: "Name", key: "name" },
                { label: "Status", key: "status" },
                {
                  label: "Stations Assigned",
                  key: "stations_count",
                },
                {
                  label: "Created At",
                  key: "created_at",
                },
                ...(canUpdateFleet()
                  ? [
                      {
                        label: "",
                        key: "actions",
                        width: "120px",
                      },
                    ]
                  : []),
              ]}
              rows={contractListData?.map((contractListItem) => {
                const {
                  name = "",
                  status,
                  metrics,
                  created_at,
                  id,
                  fleet_id,
                } = contractListItem || {};
                const { stations_count } = metrics || {};
                const { chipColor, chipBackground, chipLabel } = getStatusInfo(
                  theme.palette,
                  status
                );

                return {
                  rowsx: {
                    "& .actions_button": {
                      visibility: "hidden",
                      opacity: 0,
                      transition: "visibility 0.2s linear,opacity 0.2s linear",
                    },
                    "&:hover": {
                      "& .actions_button": {
                        visibility: "visible",
                        opacity: 1,
                      },
                    },
                  },
                  name: (
                    <Typography fontSize={14} noWrap={true}>
                      {name}
                    </Typography>
                  ),
                  status: (
                    <Box
                      sx={{
                        position: "relative",
                        width: "fit-content",
                        py: 0.25,
                        px: 1.25,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: chipBackground,
                          opacity: 0.2,
                          borderRadius: "14px",
                        }}
                      />
                      <Typography
                        fontSize={14}
                        fontWeight={"700"}
                        color={chipColor}
                      >
                        {chipLabel}
                      </Typography>
                    </Box>
                  ),
                  stations_count: (
                    <Box
                      fontSize={14}
                      sx={{
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          minWidth: "40px",
                        }}
                      >
                        {stations_count > 0
                          ? String(stations_count).padStart(2, "0")
                          : "-"}
                      </Typography>

                      {stations_count > 0 && (
                        <IonageTooltip
                          placement="right"
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                          }}
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              background: "#fff",
                              border: "1px solid",
                              borderColor: theme.palette.grey[300],
                              px: 2,
                              py: 1.5,
                              borderRadius: 2,
                              boxShadow:
                                "18px 18px 30px 0px rgba(53, 53, 53, 0.08)",
                            },
                            "& .MuiTooltip-arrow:before": {
                              background: "#fff",
                              border: "1px solid",
                              borderColor: theme.palette.grey[300],
                            },
                          }}
                          title={
                            <StationCountTable
                              tableStyle={{ minWidth: "120px" }}
                              metrics={metrics}
                            />
                          }
                          children={
                            <EyeOpen
                              fill={theme.palette.grey[500]}
                              width={20}
                              height={20}
                            />
                          }
                        />
                      )}
                    </Box>
                  ),
                  created_at: (
                    <Typography
                      fontFamily={"DM Mono"}
                      fontSize={14}
                      noWrap={true}
                    >
                      {created_at
                        ? `${new Date(created_at)?.toLocaleDateString("en-IN", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "2-digit",
                          })} ${new Date(created_at)?.toLocaleTimeString(
                            "en-IN",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}`
                        : ""}
                    </Typography>
                  ),
                  ...(canUpdateFleet()
                    ? {
                        actions: (
                          <Box
                            className="actions_button"
                            flex={1}
                            display={"flex"}
                            gap={"0.5rem"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                          >
                            <IonButtonMultiVariant
                              onClick={() => {
                                setContractModalOpen(true);
                                setContractModalData({
                                  contractId: id,
                                  fleetId: fleet_id,
                                });
                              }}
                              color="button"
                              sx={{
                                height: 32,
                                width: 32,
                                minWidth: 32,
                                padding: 0,
                              }}
                            >
                              <EditIcon
                                width={16}
                                height={16}
                                fill="currentColor"
                              />
                            </IonButtonMultiVariant>
                          </Box>
                        ),
                      }
                    : {}),
                };
              })}
            />

            {contractListLoading ? (
              <TableLoader rowCount={5} />
            ) : (
              !contractListData?.length && (
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: 240,
                  }}
                >
                  <Typography>No records found.</Typography>
                </Box>
              )
            )}
          </div>
        </Box>
        <Modal
          open={!!contractModalOpen}
          onClose={handleCloseModal(handleContractModalClose)}
          closeAfterTransition
        >
          <ContractModal
            modalOpen={!!contractModalOpen}
            handleClose={handleContractModalClose}
            fleetId={contractModalData?.fleetId || fleetId}
            contractId={contractModalData?.contractId}
            fleetData={fleetData}
            onContractAddCallback={onContractAddCallback}
          />
        </Modal>
      </Box>
    </Box>
  );
};
