import React, { useMemo } from "react";

import { Box, BoxProps, Typography } from "@mui/material";
import { ReactComponent as UpwardsIcon } from "@assets/icons/upwards-icon.svg";
import { ReactComponent as DownwardsIcon } from "@assets/icons/downwards-icon.svg";
import {
  convert_general_value,
  convert_hr_ddhhmm,
  convert_kg_value,
  convert_kWh_MWh,
  getClippedValue,
} from "@utils/utils";

interface StatsCardProps extends Omit<BoxProps, "prefix"> {
  totalStatValue?: {
    previous?: number;
    current: number;
  };
  previousPeriod?: string;
  label?: string;
  unit?: string;
  showPreviousData?: boolean;
  prefix?: React.ReactNode;
  valueType: "general" | "hr" | "kWh" | "kg";
}

export const getUnitAdjustedValue = (
  value: number = 0,
  valueType: StatsCardProps["valueType"]
) => {
  if (!isFinite(value)) {
    return "N/A";
  }
  switch (valueType) {
    case "general": {
      const { adjustedValue, unit } = convert_general_value(value);
      return `${getClippedValue(adjustedValue)} ${unit ? unit : ""}`;
    }
    case "hr": {
      return convert_hr_ddhhmm(value);
    }
    case "kWh": {
      const { adjustedValue, unit } = convert_kWh_MWh(value);
      return `${getClippedValue(adjustedValue)} ${unit ? unit : ""}`;
    }
    case "kg": {
      const { adjustedValue, unit } = convert_kg_value(value);
      return `${getClippedValue(adjustedValue)}${unit ? unit : ""}`;
    }
    default: {
      return value;
    }
  }
};

export const UpwardDownwardComponent = ({
  totalStatValue,
}: {
  totalStatValue: StatsCardProps["totalStatValue"];
}) => {
  const { previous, current } = totalStatValue || {};

  const statChangePercentage = useMemo<number | string>(() => {
    const previousNumber = Number(previous || 0);
    const currentNumber = Number(current);
    if (
      !isFinite(previousNumber) ||
      !isFinite(currentNumber) ||
      previousNumber === 0
    ) {
      return "N/A";
    }
    let PERCENTAGE = 0;
    PERCENTAGE =
      Math.round(
        (((currentNumber - previousNumber) * 100) / previousNumber) * 100
      ) / 100;
    if (Math.abs(PERCENTAGE) < 10) {
      return PERCENTAGE;
    } else if (Math.abs(PERCENTAGE) < 100) {
      PERCENTAGE = Math.round(PERCENTAGE * 10) / 10;
    } else {
      PERCENTAGE = Math.round(PERCENTAGE);
    }
    return PERCENTAGE;
  }, [current, previous]);

  if (previous === undefined || current === undefined) {
    return null;
  }

  const ICONSIZE = 14;
  const FONTSIZE = 14;

  return (
    <Box mt={0.5}>
      {Number(current) >= Number(previous) ? (
        <Box display={"flex"} alignItems={"center"}>
          <UpwardsIcon height={ICONSIZE} width={ICONSIZE} />{" "}
          <Typography
            ml={0.5}
            fontSize={FONTSIZE}
            color="success.main"
            fontWeight={"bold"}
          >{`${statChangePercentage}%`}</Typography>
        </Box>
      ) : (
        <Box display={"flex"} alignItems={"center"}>
          <DownwardsIcon height={ICONSIZE} width={ICONSIZE} />{" "}
          <Typography
            ml={0.5}
            fontSize={FONTSIZE}
            color="error.main"
            fontWeight={"bold"}
          >{`${statChangePercentage}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

const StatsCard = ({
  totalStatValue,
  label,
  unit,
  prefix,
  previousPeriod,
  showPreviousData = false,
  valueType = "general",
  ...boxProps
}: StatsCardProps) => {
  return (
    <Box>
      <Typography fontWeight={"500"} fontSize={14}>
        {label || "Card label"}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <Typography mr={1} fontSize={26} lineHeight={"32px"} fontWeight={"500"}>
          {prefix}
          {getUnitAdjustedValue(totalStatValue?.current, valueType)}
          {unit && (
            <Typography
              component={"span"}
              fontSize={14}
              ml={0.5}
              sx={{ opacity: 0.8 }}
            >
              {unit}
            </Typography>
          )}
        </Typography>
        <UpwardDownwardComponent totalStatValue={totalStatValue} />
      </Box>
      {showPreviousData && totalStatValue?.previous !== undefined && (
        <Box mt={1} display="flex" alignItems={"center"}>
          <Typography mr={3} fontSize={14}>
            {previousPeriod || "00 Jan - 00 Jan"}
          </Typography>
          <Typography fontSize={14} fontWeight={"500"}>
            {getUnitAdjustedValue(totalStatValue?.previous, valueType)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StatsCard;
