import { createSlice } from "@reduxjs/toolkit";

export interface TenantState {
  components?: {
    ocpi?: {
      active: boolean;
      type: string;
    };
    fleets?: {
      active: boolean;
      type: string;
    };
    oicp?: {
      active: boolean;
      type: string;
    };
    refund?: {
      active: boolean;
      type: string;
    };
    pricing?: {
      active: boolean;
      type: string;
    };
    organization?: {
      active: boolean;
    };
    statistics?: {
      active: boolean;
    };
    analytics?: {
      active: boolean;
      type: string;
    };
    billing?: {
      active: boolean;
      type: string;
    };
    asset?: {
      active: boolean;
    };
    smartCharging?: {
      active: boolean;
      type: string;
    };
    car?: {
      active: boolean;
    };
    carConnector?: {
      active: boolean;
    };
  };
  email?: string;
  name?: string;
  subdomain?: string;
  address?: {
    address1?: string;
    address2?: string;
    postalCode?: string;
    city?: string;
    department?: string;
    region?: string;
    country?: string;
    coordinates?: [string, string];
  };
  bannerLogo?: string;
  type?: string;
  logo?: string;
  id?: string;
}

const initialState: TenantState = {};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type === "CHANGE_TENANT",
      (state, action) => {
        return { ...state, ...action.payload };
      }
    );
  },
});

export default tenantSlice.reducer;
