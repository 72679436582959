export const truncDecimals = (value: number, decimalPlaces: number = 0) => {
  let multiplier = 1;
  if (decimalPlaces > 0) {
    multiplier = Math.pow(10, decimalPlaces);
  }
  return ~~(value * multiplier) / multiplier; //~~ bitwise not to remove decimal places
};

export const getClippedValue = (value: number = 0) => {
  let adjustedValue = value || 0;
  if (adjustedValue < 0) {
    //Negative numbers
  } else if (adjustedValue < 10) {
    adjustedValue = truncDecimals(adjustedValue, 2);
  } else if (adjustedValue < 100) {
    adjustedValue = truncDecimals(adjustedValue, 1);
  } else if (adjustedValue >= 100) {
    adjustedValue = truncDecimals(adjustedValue, 0);
  }
  return adjustedValue;
};

export const convert_kg_value = (value: number = 0) => {
  let adjustedValue = value || 0;
  let unit = "kg";

  if (adjustedValue < 0) {
    //Negative numbers
  } else if (adjustedValue < 1) {
    unit = "g";
    adjustedValue = truncDecimals(adjustedValue * 1000);
  } else if (adjustedValue < 1000) {
    //return default
  } else if (adjustedValue >= 1000) {
    unit = "t";
    adjustedValue = truncDecimals(adjustedValue / 1000, 2);
  }
  return { adjustedValue, unit };
};

export const convert_general_value = (value: number = 0) => {
  let adjustedValue = value || 0;
  let unit = "";

  if (adjustedValue < 0) {
    //Negative numbers
  } else if (value >= 1000 && value < 100000) {
    unit = "k";
    adjustedValue = truncDecimals(adjustedValue / 1000, 2);
  } else if (value >= 100000 && value < 10000000) {
    //return default
    unit = "L";
    adjustedValue = truncDecimals(adjustedValue / 100000, 2);
  } else if (value >= 10000000) {
    unit = "cr";
    adjustedValue = truncDecimals(adjustedValue / 10000000, 2);
  }
  return { adjustedValue, unit };
};

export const convert_hr_ddhhmm = (value: number = 0) => {
  let adjustedValue = value || 0;

  const totalSeconds = adjustedValue * 3600;
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor(totalSeconds / 3600);
  const day = Math.floor(hours / 24);

  if (day >= 100) {
    return `${day || 0}d`;
  } else if (hours >= 24) {
    const restHours = hours % 24;
    return `${day || 0}d ${restHours || 0}h`;
  } else if (hours >= 1) {
    return `${hours || 0}h ${minutes || 0}m`;
  }
  return `${minutes || 0}min`;
};

export const convert_kWh_MWh = (value: number = 0) => {
  let adjustedValue = value || 0;
  let unit = "kWh";
  if (adjustedValue < 0) {
    //Negative numbers
  } else if (value >= 1000) {
    unit = "MWh";
    adjustedValue = truncDecimals(adjustedValue / 1000, 2);
  }
  return { adjustedValue, unit };
};
