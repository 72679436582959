import { AlertColor, Box, Slide, useTheme } from "@mui/material";
import { FleetAppBar } from "./FleetAppBar";
import {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import IonSnackbar from "@components/ionSnackbar";
import { getFleetByID } from "@nexusAPI/fleet";
import { FleetPane } from "./FleetPane";

export interface FleetInfoModalProps {
  fleetId: number;
  handleClose: (event: any) => void;
  handleUpdate?: (value: boolean) => void;
}

export const FleetInfoModalContext = createContext<{
  fleetData?: Awaited<ReturnType<typeof getFleetByID>>;
  handleClose: FleetInfoModalProps["handleClose"];
  fleetId: FleetInfoModalProps["fleetId"];
  getFleet: () => void;
  setSnackbar?: (
    value: React.SetStateAction<{
      message: string;
      severity?: AlertColor | undefined;
    }>
  ) => void;
  handleUpdate?: FleetInfoModalProps["handleUpdate"];
}>({
  fleetId: -1,
  handleClose: () => {},
  getFleet: () => {},
  handleUpdate: (value: boolean) => {},
});

const FleetInfoModal = forwardRef(
  ({ handleClose, fleetId, handleUpdate }: FleetInfoModalProps, ref) => {
    const [fleetData, setFleetData] =
      useState<Awaited<ReturnType<typeof getFleetByID>>>();
    const theme = useTheme();

    const [snackbar, setSnackbar] = useState<{
      message: string;
      severity?: AlertColor;
    }>({
      message: "",
      severity: undefined,
    });

    const getFleet = useCallback(async () => {
      try {
        const data = await getFleetByID({ fleetId });
        setFleetData(data);
      } catch {}
    }, [fleetId]);

    useEffect(() => {
      getFleet();
    }, [getFleet]);

    return (
      <Slide in={!!fleetId} direction="left" ref={ref} tabIndex={-1}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: { xs: 40, sm: 280 },
            right: 0,
            bottom: 0,
            borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
            borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
            background: theme.palette.background.paper,
            color: theme.palette.getContrastText(
              theme.palette.background.paper
            ),
            display: "flex",
            flexDirection: "column",
            p: 2,
            outline: 0,
          }}
        >
          <FleetInfoModalContext.Provider
            value={{
              fleetData,
              handleClose,
              fleetId,
              setSnackbar,
              getFleet,
              handleUpdate,
            }}
          >
            <FleetAppBar />
            <FleetPane />
          </FleetInfoModalContext.Provider>
          {!!(snackbar.message && snackbar.severity) && (
            <IonSnackbar
              open={Boolean(snackbar.message && snackbar.severity)}
              onClose={() => {
                setSnackbar({ message: "", severity: undefined });
              }}
              severity={snackbar.severity}
              message={snackbar.message}
            />
          )}
        </Box>
      </Slide>
    );
  }
);

export default FleetInfoModal;
