import { Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { FC } from "react";
import { capabilitiesField } from "../fields";

export const capabillites = [
  {
    label: "Support Static Limitation",
    value: "supportStaticLimitation",
  },
  {
    label: "Support Charging Profiles",
    value: "supportChargingProfiles",
  },
  {
    label: "Support Remote Start Stop Transaction",
    value: "supportRemoteStartStopTransaction",
  },
  { label: "Support Unlock Connector", value: "supportUnlockConnector" },
  {
    label: "Support Reservation",
    value: "supportReservation",
  },
  {
    label: "Support RFID Card",
    value: "supportRFIDCard",
  },
  {
    label: "Support Firmware Upgrade",
    value: "supportFirmwareUpgrade",
  },
];

interface CustomCheckboxProps extends FieldProps {
  label: string;
  value: string;
}
export const CustomCheckbox: FC<CustomCheckboxProps> = ({
  label,
  value,
  form,
  field,
}) => {
  const { values, setFieldValue } = form;
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const currentValues = values.capabilities;

    if (checked) {
      setFieldValue(field.name, { ...currentValues, [value]: true });
    } else {
      setFieldValue(field.name, { ...currentValues, [value]: false });
    }
  };
  return (
    <FormControl>
      <FormControlLabel
        label={label}
        value={value}
        control={
          <Checkbox
            checked={values.capabilities?.[value]}
            onChange={handleCheck}
          />
        }
      ></FormControlLabel>
    </FormControl>
  );
};

function Capabilities() {
  return (
    <Grid container spacing={2}>
      {capabillites.map((capability) => {
        const { label, value } = capability;
        const { colCount, ...fieldProps } = capabilitiesField;
        return (
          <Grid xs={colCount} item key={value}>
            <Field {...fieldProps} value={value} label={label} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Capabilities;
