import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import MUITooltip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

interface IonageTooltipProps extends TooltipProps {}

const IonageToolTipComponent = styled(
  ({ className, ...tooltipProps }: TooltipProps) => (
    <MUITooltip classes={{ popper: className }} {...tooltipProps} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 15,
  },
}));

export const IonageTooltip = ({
  //default Props
  placement = "right",
  arrow = true,
  ...tooltipProps
}: IonageTooltipProps) => {
  return (
    <IonageToolTipComponent
      placement={placement}
      arrow={arrow}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 12],
            },
          },
        ],
      }}
      {...tooltipProps}
    >
      {tooltipProps.children}
    </IonageToolTipComponent>
  );
};

export default memo(IonageTooltip);
