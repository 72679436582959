import React from "react";
import { Box, Skeleton } from "@mui/material";

interface TableLoaderType {
  rowCount?: number;
}

const TableLoader = ({ rowCount = 1 }: TableLoaderType) => {
  return (
    <>
      {[...new Array(rowCount)].map((item, index) => (
        <Box
          data-testid="skeleton-row"
          key={index}
          sx={(theme) => ({
            px: 1,
            height: 48,
            borderBottom: "1px solid",
            borderColor: theme.palette.grey[200],
            display: "flex",
            alignItems: "center",
          })}
        >
          <Skeleton variant="rounded" width={"100%"} />
        </Box>
      ))}
    </>
  );
};

export default TableLoader;
