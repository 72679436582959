import {
  Checkbox as MCheckbox,
  FormControl,
  FormControlLabel,
  BaseTextFieldProps,
} from "@mui/material";
import { FieldProps } from "formik";
import { FC } from "react";
interface CheckBoxProps extends FieldProps, BaseTextFieldProps {
  onChange?: (event: boolean) => void;
}

export const CheckBox: FC<CheckBoxProps> = ({
  form,
  field,
  label,
  disabled,
  onChange = () => {},
}) => {
  const { setFieldValue } = form || {};
  const { name = "", value } = field || {};
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <FormControl sx={{ alignSelf: "center" }}>
      <FormControlLabel
        label={label}
        value={value}
        disabled={disabled}
        control={
          <MCheckbox
            onChange={(event) => handleChange(event)}
            checked={value}
          />
        }
      ></FormControlLabel>
    </FormControl>
  );
};
