import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import FleetCards from "./FleetCards";
import { useCallback, useEffect, useRef, useState } from "react";
import { getFleets } from "@nexusAPI/fleet";
import SearchFilter from "@components/filters/searchFilter";
import FleetInfoModal from "./fleetInfoModal";

const FLEET_LIST_LIMIT = 50;

const FleetDiscoverScreen = () => {
  const [fleetListData, setFleetListData] = useState<
    Awaited<ReturnType<typeof getFleets>>["data"]
  >([]);
  const [scrolledIndex, setScrolledIndex] = useState<number>(0);
  const searchCloseRef = useRef<SVGSVGElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fleetListLoading, setFleetListLoading] = useState<boolean>(true);
  const [fleetInfoModalOpen, setFleetInfoModalOpen] = useState<number>(-1);

  const handleFleetInfoModalClose = () => {
    setFleetInfoModalOpen(-1);
  };

  const handleCloseModal =
    (fn: Function) =>
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        fn();
      }
    };

  const getFleetListData = useCallback(
    async (filters: Parameters<typeof getFleets>[0]) => {
      try {
        setFleetListLoading(true);
        const response = await getFleets(filters);
        const { data = [] } = response || {};
        setFleetListData((prevData) => [...prevData, ...data]);
      } catch {
      } finally {
        setFleetListLoading(false);
      }
    },
    []
  );

  const onContractUpdateCallback = () => {
    resetTable();
    if (searchCloseRef.current) {
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      searchCloseRef.current.dispatchEvent(clickEvt);
    } else {
      getFleetListData &&
        getFleetListData({
          limit: FLEET_LIST_LIMIT,
          skip: 0,
        });
    }
  };

  useEffect(() => {
    const debounceCall = setTimeout(() => {
      getFleetListData &&
        getFleetListData({
          limit: FLEET_LIST_LIMIT,
          skip: 0,
          ...(searchValue !== "" && { search: searchValue }),
        });
    }, 500);
    return () => clearTimeout(debounceCall);
  }, [getFleetListData, scrolledIndex, searchValue]);

  const resetTable = useCallback(() => {
    setFleetListData([]);
    setScrolledIndex(0);
  }, []);

  const onSearchFilterHandle = useCallback(
    (value: string) => {
      if (searchValue === value) {
        return;
      }
      resetTable();
      setSearchValue(value);
    },
    [resetTable, searchValue]
  );

  return (
    <Box sx={{ py: 4.5, px: 2.5, width: "100%" }}>
      <Container
        disableGutters
        component={"main"}
        maxWidth={"xl"}
        sx={{ height: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 8,
          }}
        >
          <Typography component={"h2"} sx={{ fontSize: 24, fontWeight: 700 }}>
            Registered Fleets
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SearchFilter
              sx={{ width: "480px" }}
              ref={searchCloseRef}
              placeholder="Search by name"
              onChange={onSearchFilterHandle}
            />
          </Box>
          {/* will be release in 2nd phase */}
        </Box>
        <Box sx={{ overflowY: "scroll", height: "100%" }}>
          {!fleetListLoading &&
            (fleetListData.length > 0 ? (
              <Grid container spacing={2}>
                {fleetListData.map((fleet) => {
                  return (
                    <Grid item xs={12} md={6} key={fleet.name}>
                      <FleetCards
                        {...fleet}
                        setFleetInfoModalOpen={setFleetInfoModalOpen}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minHeight: 240,
                }}
              >
                <Typography>No records found.</Typography>
              </Box>
            ))}
        </Box>
      </Container>
      <Modal
        open={fleetInfoModalOpen > -1}
        onClose={handleCloseModal(handleFleetInfoModalClose)}
        closeAfterTransition
      >
        <FleetInfoModal
          fleetId={fleetInfoModalOpen}
          handleClose={handleFleetInfoModalClose}
          handleUpdate={onContractUpdateCallback}
        />
      </Modal>
    </Box>
  );
};

export default FleetDiscoverScreen;
