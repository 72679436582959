import {
  Avatar,
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { IonButton } from "@components/ionButton";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { ReactComponent as ChevRight } from "@assets/icons/chev-left-icon.svg";

import { getFleetByID } from "@nexusAPI/fleet";
import { canUpdateFleet } from "@utils/authService";
export interface FleetCardProps
  extends Awaited<ReturnType<typeof getFleetByID>> {
  setFleetInfoModalOpen?: (id: number) => void;
}

const FleetCards = ({
  id,
  category,
  operational_regions,
  operational_size,
  vehicle_types,
  name,
  metrics,
  logo_url,
  setFleetInfoModalOpen,
}: FleetCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: "#fff",
        py: 2.5,
        px: 2,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={logo_url}
            sx={{
              height: 50,
              width: 50,
              mr: 1.5,
            }}
          />
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              {name}
            </Typography>
            <Typography
              sx={{
                fontWeight: metrics.contract_count > 0 ? 500 : 400,
                color:
                  metrics.contract_count > 0
                    ? theme.palette.info.main
                    : theme.palette.grey[600],
              }}
            >
              {metrics.contract_count} Contracts
            </Typography>
          </Box>
        </Box>
        {metrics.contract_count > 0 ? (
          <IonButton
            variant="outlined"
            color="button"
            style={{ fontSize: "24px", fontWeight: "400" }}
            sx={{
              height: 40,
              minWidth: 40,
              padding: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              // "& .MuiButton-startIcon": {
              //   marginLeft: 4,
              //   marginRight: 0,
              // },""
              "&:hover": {
                "& svg": {
                  mx: 1,
                },
                "& span": {
                  // mx: 2,
                  mr: 1,
                  width: "52px",
                },
              },
              transition: "width 0.3s ease",
            }}
            onClick={() => {
              setFleetInfoModalOpen && setFleetInfoModalOpen(id);
            }}
          >
            <ChevRight stroke="currentColor" height={20} width={20} />
            <Typography
              component="span"
              sx={{
                width: 0,
                transition: "width 0.3s ease",
                overflow: "hidden",
                // display: "inline-block",
                whiteSpace: "nowrap",
              }}
            >
              Details
            </Typography>
          </IonButton>
        ) : (
          canUpdateFleet() && (
            <IonButton
              variant="contained"
              color="button"
              style={{ fontSize: "24px", fontWeight: "400" }}
              sx={{
                height: 40,
                minWidth: 40,
                padding: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                // "& .MuiButton-startIcon": {
                //   marginLeft: 4,
                //   marginRight: 0,
                // },""
                "&:hover": {
                  "& svg": {
                    mx: 1,
                  },
                  "& span": {
                    // mx: 2,
                    mr: 1,
                    width: "108px",
                  },
                },
                transition: "width 0.3s ease",
              }}
              onClick={() => {
                setFleetInfoModalOpen && setFleetInfoModalOpen(id);
              }}
            >
              <POIAdd fill="currentColor" height={24} width={24} />
              <Typography
                component="span"
                sx={{
                  width: 0,
                  transition: "width 0.3s ease",
                  overflow: "hidden",
                  // display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                Onboard Fleet
              </Typography>
            </IonButton>
          )
        )}
      </Box>
      <Divider
        sx={{
          mt: 4.5,
          mb: 4.5,
          background:
            "linear-gradient(90deg, #FFF 0%, #E0E0E0 25%, #E0E0E0 79%, #FFF 100%)",
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: "14px", color: theme.palette.grey[600] }}>
            Category
          </Typography>
          <Typography>{category}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: "14px", color: theme.palette.grey[600] }}>
            Operational Size
          </Typography>
          <Typography>{operational_size}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: "14px", color: theme.palette.grey[600] }}>
            Vehicle Types
          </Typography>
          <Typography>{vehicle_types.join(", ")}</Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "14px", color: theme.palette.grey[600] }}>
            Operational Region
          </Typography>
          <Typography>{operational_regions.join(", ")}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FleetCards;
