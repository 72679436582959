import React, { useState, memo, forwardRef } from "react";
import {
  TextField as MTextField,
  BaseTextFieldProps,
  InputAdornment,
} from "@mui/material";

import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";

export interface SearchFilterProps extends BaseTextFieldProps {
  onChange?: (value: string) => void;
}

const FIELD_HEIGHT = 40;

const DEBOUNCE_TIME = 1000;

const SearchFilter = forwardRef<SVGSVGElement, SearchFilterProps>(
  ({ onChange = () => {}, variant = "outlined", ...mTextFieldProps }, ref) => {
    const [searchValue, setSearchValue] = useState<string>("");
    React.useEffect(() => {
      const delayInputTimeoutId = setTimeout(() => {
        onChange(searchValue);
      }, DEBOUNCE_TIME);
      return () => clearTimeout(delayInputTimeoutId);
    }, [onChange, searchValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    };

    return (
      <MTextField
        id="search_filter"
        InputProps={{
          sx: (theme) => ({
            borderRadius: 2,
            minWidth: 360,
            height: FIELD_HEIGHT,
            background: theme.palette.common.white,
            boxShadow: theme.shadows[0],
            "&:has(> input:-webkit-autofill)": {
              backgroundColor: "#D5E0ED",
            },
            "& input": {
              boxSizing: "border-box",
              height: "100%",
              "&:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 100px #D5E0ED inset`,
              },
            },
          }),
          endAdornment: (
            <InputAdornment position="end">
              {searchValue ? (
                <CloseIcon
                  ref={ref}
                  onClick={() => setSearchValue("")}
                  width={24}
                  height={24}
                  style={{ cursor: "pointer" }}
                  fill="currentColor"
                />
              ) : (
                <SearchIcon width={24} height={24} fill="currentColor" />
              )}
            </InputAdornment>
          ),
        }}
        variant={variant}
        {...mTextFieldProps}
        value={searchValue}
        onChange={handleChange}
      />
    );
  }
);

export default memo(SearchFilter);
