import React, { FC } from "react";
import { FieldProps, getIn } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface RadioGroupFieldProps extends FieldProps {
  label: string;
  options: Array<{ label: string; value: string }>;
}

const RadioGroupField: FC<RadioGroupFieldProps> = ({
  field,
  form,
  label,
  options,
}) => {
  const { name, value } = field;
  const { errors, touched, setFieldValue } = form;

  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const errorMsg = touch && error;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <FormControl error={Boolean(errorMsg)}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange} row>
        {options.map((opt) => (
          <FormControlLabel
            key={opt.value}
            value={opt.value}
            control={<Radio />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
      {errorMsg && <FormHelperText>{errorMsg}</FormHelperText>}
    </FormControl>
  );
};

export default RadioGroupField;
