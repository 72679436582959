import { nexusAPI } from "@utils/APIInterceptor";
import { ApiEndpoints, RESTServerRoute } from "../types/Server";

async function getChargingStationsConnector() {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<
      Array<{ type: string; display_text: string; type_url: string }>
    >(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_CHARGING_STATION_CONNECTORS}`
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

export { getChargingStationsConnector };
