import { nexusAPI } from "@utils/APIInterceptor";
import { Contract, Fleet, FleetResult, FleetMetricsType } from "../types/Fleet";
import { ApiEndpoints, RESTServerRoute } from "../types/Server";

export const getFleets = async (payload: {
  onboarded?: boolean;
  limit?: number;
  skip?: number;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const response = await nexusAPI(token).get<FleetResult<Fleet>>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}`,
      { params: payload }
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const getFleetsMetrics = async (payload: {
  dateTo?: string;
  dateFrom?: string;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const response = await nexusAPI(token).get<FleetMetricsType>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/metrics`,
      { params: payload }
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const getFleetByID = async (payload: { fleetId: number }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const { fleetId } = payload;
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    const response = await nexusAPI(token).get<FleetResult<Fleet>>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}`
    );
    const { data } = response;
    const { data: subData = [] } = data || {};
    return subData[0];
  } catch (err) {
    throw err;
  }
};

export const getFleetsMetricsByID = async (payload: {
  fleetId: number;
  dateTo?: string;
  dateFrom?: string;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const { fleetId, ...restPayload } = payload;
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    const response = await nexusAPI(token).get<FleetMetricsType>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}/metrics`,
      { params: restPayload }
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const getContracts = async (payload: {
  fleetId: number;
  active?: boolean;
  limit?: number;
  skip?: number;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const { fleetId, ...restPayload } = payload;
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    const response = await nexusAPI(token).get<FleetResult<Contract>>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}/${RESTServerRoute.REST_CONTRACTS}`,
      { params: restPayload }
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const getContractById = async (payload: {
  fleetId: number;
  contractId: number;
  active?: boolean;
  limit?: number;
  skip?: number;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("token not found");
    }
    const { fleetId, contractId, ...restPayload } = payload;
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    if (!contractId) {
      throw new Error("contractId not found");
    }
    const response = await nexusAPI(token).get<Contract>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}/${RESTServerRoute.REST_CONTRACTS}/${contractId}`,
      { params: restPayload }
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw err;
  }
};

export const postContract = async (
  payload: {
    fleetId: number;
  },
  body: {
    name: string;
    defaultTariffId: string;
    defaultRestriction: string;
    status: string;
    stations?: {
      id: string;
      tariffId: string;
      restriction: string;
    }[];
  }
) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { fleetId } = payload || {};
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    const response = await nexusAPI(token).post<Contract>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}/${RESTServerRoute.REST_CONTRACT}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const patchContract = async (
  payload: {
    fleetId: number;
    contractId: number;
  },
  body: Partial<Contract> & {
    updatedStations?: {
      id: string;
      tariffId: string;
      restriction: string;
    }[];
    defaultTariffId: string;
    defaultRestriction: string;
    deletedStationIds?: string[];
  }
) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { fleetId, contractId } = payload || {};
    if (!fleetId) {
      throw new Error("fleetId not found");
    }
    if (!contractId) {
      throw new Error("contractId not found");
    }
    const response = await nexusAPI(token).patch<{ message: string }>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_FLEETS}/${fleetId}/${RESTServerRoute.REST_CONTRACTS}/${contractId}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};
