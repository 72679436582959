import { IonColors } from "@config/theme/IonColors";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { forwardRef } from "react";
import { Contract } from "types/Fleet";
interface ConnectorTableProps {
  metrics: Contract["metrics"];
  tableStyle?: {};
}

const StationCountTable = forwardRef(
  ({ metrics, tableStyle }: ConnectorTableProps, ref) => {
    const theme = useTheme();
    const { dedicated_stations_count = 0, shared_stations_count = 0 } =
      metrics || {};
    const rows = [
      {
        name: "Dedicated",
        value: dedicated_stations_count,
        color: theme.palette.success.main,
      },
      {
        name: "Shared",
        value: shared_stations_count,
        color: IonColors.ionDiamond[600],
      },
    ];
    return (
      <Box>
        <Table
          sx={{
            minWidth: "184px",
            ...tableStyle,
          }}
        >
          <TableBody>
            {rows.map((row, index) => {
              return (
                row.value > 0 && (
                  <TableRow
                    sx={{
                      height: 25,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TableCell
                      sx={{
                        border: "none",
                        color: row.color || "inherit",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                      padding="none"
                    >
                      <Typography
                        sx={{
                          height: 16,
                          width: 16,
                          background: row.color,
                          borderRadius: "99px",
                        }}
                      />
                      {row.name}
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={{
                        border: "none",
                      }}
                    >
                      {row.value}
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
);

export default StationCountTable;
