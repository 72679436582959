import React, { FC, useState } from "react";
import { FieldProps } from "formik";
import {
  TextField as MTextField,
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  alpha,
  InputBaseProps,
  useTheme,
} from "@mui/material";
import { ReactComponent as VisibilityIcon } from "@assets/materialIcons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "@assets/materialIcons/visibility_off.svg";
import { IonIconButton } from "@components/ionButton";

interface TextFieldType extends Partial<FieldProps>, BaseTextFieldProps {
  disabled?: boolean;
  variant?: "outlined" | "filled" | "standard";
  prefix?: string;
  suffix?: string;
  options?: Array<any>;
  onChange?: (value: string) => void;
  InputProps?: Partial<InputBaseProps>;
  textTransform?: "uppercase" | "lowercase" | "";
  maxLength?: number;
}

const TextField: FC<TextFieldType> = ({
  field,
  form,
  disabled = false,
  prefix,
  suffix,
  onBlur = () => {},
  onChange = () => {},
  options,
  textTransform = "",
  variant = "outlined",
  color = "field",
  maxLength,
  ...mTextFieldProps
}) => {
  const { name = "", value } = field || {};
  const { setFieldValue, touched, errors, setFieldTouched } = form || {};
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const theme = useTheme();

  const touch = touched && touched[name];
  const error = errors && errors[name];
  const errorMsg = touch && error;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let finalValue = event.target.value;
    switch (textTransform) {
      case "uppercase":
        finalValue = finalValue.toUpperCase();
        break;
      case "lowercase":
        finalValue = finalValue.toLowerCase();
        break;
      default:
        break;
    }
    setFieldValue && setFieldValue(name, finalValue);
    onChange(finalValue);
  };

  const handleTogglePasswordShow = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  return (
    <MTextField
      fullWidth={true}
      id={name}
      disabled={disabled}
      error={Boolean(errorMsg)}
      helperText={errorMsg ? String(errorMsg) : ""}
      color={color}
      value={value}
      variant={variant}
      onChange={handleChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: `0 0 0 100px ${alpha(
                theme.palette.primary.main,
                0.1
              )} inset`,
            },
          },
          "&:has(> input:-webkit-autofill)": {
            backgroundColor: "#D5E0ED",
          },
        },
        ...mTextFieldProps.sx,
      }}
      InputProps={{
        sx: {
          borderRadius: 2,
        },
        inputProps: {
          maxLength: maxLength,
        },
        ...(prefix
          ? {
              startAdornment: (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ),
            }
          : {}),
        ...(suffix
          ? {
              endAdornment: (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ),
            }
          : {}),
        ...(mTextFieldProps.type === "number"
          ? {
              sx: {
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            }
          : {}),
        ...(mTextFieldProps.type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IonIconButton
                    color="inherit"
                    onClick={handleTogglePasswordShow}
                  >
                    {!showPassword ? (
                      <VisibilityIcon
                        width={"1em"}
                        height={"1em"}
                        fill="currentColor"
                      />
                    ) : (
                      <VisibilityOffIcon
                        width={"1em"}
                        height={"1em"}
                        fill="currentColor"
                      />
                    )}
                  </IonIconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      {...mTextFieldProps}
      {...(mTextFieldProps.type === "password"
        ? {
            type: showPassword ? "text" : "password",
          }
        : {})}
      {...(mTextFieldProps.select
        ? {
            SelectProps: {
              autoWidth: false,
              MenuProps: {
                sx: (theme) => ({
                  "& .MuiPaper-root": {
                    mt: 0.5,
                    borderRadius: 2,
                    boxShadow: theme.shadows[1],
                    "& .MuiMenuItem-root": {
                      color: theme.palette.text.primary,
                      "&:hover": {
                        backgroundColor: alpha(
                          theme.palette[color]?.main ||
                            theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                        ),
                      },
                      "&.Mui-selected": {
                        backgroundColor: alpha(
                          theme.palette[color]?.main ||
                            theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                        ),
                        "&:hover": {
                          backgroundColor: alpha(
                            theme.palette[color]?.main ||
                              theme.palette.primary.main,
                            theme.palette.action.selectedOpacity + 0.02
                          ),
                        },
                      },
                    },
                  },
                }),
              },
            },
          }
        : {})}
      onBlur={(event) => {
        setFieldTouched && setFieldTouched(name, true);
        onBlur(event);
      }}
    >
      {!!mTextFieldProps.select &&
        (options && options.constructor === Array && options.length ? (
          options.map((opt: any, index) => {
            const { value, label } = opt || {};
            return (
              <MenuItem key={value} value={value || ""}>
                {label}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""}>No Items</MenuItem>
        ))}
    </MTextField>
  );
};

export default TextField;
