import { IonButton } from "@components/ionButton";
import { Badge } from "@mui/material";
import { useCallback, useState } from "react";
import { Range } from "react-date-range";
import DatePickerPopover from "./DatePickerPopover";
import { ReactComponent as CalendarMonthIcon } from "@assets/icons/calendar_month.svg";

export interface DatePickerProps {
  dateSelection: Range | undefined;
  setDateSelection: React.Dispatch<React.SetStateAction<Range | undefined>>;
}

const DatePicker = ({ dateSelection, setDateSelection }: DatePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isSavedDateUsed, setIsSavedDateUsed] = useState<boolean>(false);

  const handleDateFilterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const onSavedDateSelectionChange = useCallback((savedDate?: Range) => {
    setIsSavedDateUsed(!!savedDate);
  }, []);

  return (
    <>
      <Badge variant="dot" color="primary" invisible={!isSavedDateUsed}>
        <IonButton
          color="button"
          variant="contained"
          onClick={handleDateFilterButtonClick}
          sx={{
            height: 40,
          }}
          startIcon={
            <CalendarMonthIcon width={20} height={20} fill="currentColor" />
          }
        >
          {dateSelection?.startDate && dateSelection?.endDate
            ? `${dateSelection.startDate.toLocaleString("default", {
                day: "2-digit",
                month: "short",
              })} - ${dateSelection.endDate.toLocaleString("default", {
                day: "2-digit",
                month: "short",
              })}`
            : "00 Jan - 00 Jan"}
        </IonButton>
      </Badge>
      <DatePickerPopover
        dateSelection={dateSelection}
        setDateSelection={setDateSelection}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onSavedDateSelectionChange={onSavedDateSelectionChange}
      />
    </>
  );
};

export default DatePicker;
