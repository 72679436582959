import { Box, Typography } from "@mui/material";
import { FleetInfoModalContext } from "./FleetInfoModal";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { useContext } from "react";
import { IonButton } from "@components/ionButton";

export const FleetAppBar = () => {
  const { handleClose } = useContext(FleetInfoModalContext);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IonButton
        sx={(theme) => ({
          p: 0,
          minWidth: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: theme.palette.background.card,
          color: theme.palette.getContrastText(theme.palette.background.card),
          boxShadow: theme.shadows[1],
          "&:active": {
            boxShadow: theme.shadows[2],
          },
          "&:hover": {
            boxShadow: theme.shadows[2],
          },
        })}
        onClick={handleClose}
      >
        <CloseIcon fill={"currentColor"} width={20} height={20} />
      </IonButton>
      <Typography ml={2} sx={{ flex: 1 }} fontWeight={"700"} fontSize={"20px"}>
        Fleet Details
      </Typography>
    </Box>
  );
};
