import { IonButton } from "@components/ionButton";
import { Box, Popover, styled, useTheme } from "@mui/material";
import { useLocalStorage } from "@utils/miscHooks";

import { useEffect, useMemo, useState } from "react";
import {
  createStaticRanges,
  DateRangePicker,
  DateRangePickerProps,
  Range,
  RangeKeyDict,
} from "react-date-range";

const DateRangePickerStyled = styled(DateRangePicker)<DateRangePickerProps>(
  ({ theme }) => ({
    fontFamily: "inherit",
    minHeight: "384px",
    "& .rdrStaticRange,.rdrInputRangeInput": {
      fontFamily: "inherit",
    },
    "& .rdrDateDisplayItem,.rdrNextPrevButton,.rdrInputRangeInput,.rdrMonthAndYearPickers select":
      {
        borderRadius: Number(theme.shape.borderRadius) * 2,
      },
    "& .rdrDateDisplayItemActive": {
      borderWidth: "0px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& input": {
        color: "inherit",
      },
    },
  })
);

export interface DatePickerPopoverProps {
  dateSelection: Range | undefined;
  setDateSelection: React.Dispatch<React.SetStateAction<Range | undefined>>;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onSavedDateSelectionChange?: (dateSelection: Range | undefined) => void;
  doNotSaveDate?: boolean;
}
const today = new Date();
const defineds = {
  startOfThisMonth: new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
    0,
    0,
    0,
    0
  ),
  endOfLastMonth: new Date(
    today.getFullYear(),
    today.getMonth(),
    0,
    23,
    59,
    59,
    999
  ),
  startOfLastMonth: new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1,
    0,
    0,
    0,
    0
  ),
  startOfLast3Month: new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    1,
    0,
    0,
    0,
    0
  ),
  startOfLast6Month: new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    1,
    0,
    0,
    0,
    0
  ),
  startOfLast12Month: new Date(
    today.getFullYear(),
    today.getMonth() - 12,
    1,
    0,
    0,
    0,
    0
  ),
  startOfLastFinancialYear: new Date(
    today.getFullYear() - 1,
    today.getMonth() >= 3 ? 3 : 3 - 12,
    1,
    0,
    0,
    0,
    0
  ),
  endOfLastFinancialYear: new Date(
    today.getFullYear() - 1,
    today.getMonth() >= 3 ? 3 + 12 : 3,
    0,
    23,
    59,
    59,
    999
  ),
  startOfFinancialYear: new Date(
    today.getFullYear(),
    today.getMonth() >= 3 ? 3 : 3 - 12,
    1,
    0,
    0,
    0,
    0
  ),
  endOfFinancialYear: new Date(
    today.getFullYear(),
    today.getMonth() >= 3 ? 3 + 12 : 3,
    0,
    23,
    59,
    59,
    999
  ),
};

const isSelectedHandler = (definedRange: Range) => (range: Range) => {
  const { startDate, endDate } = range || {};
  const { startDate: definedStartDate, endDate: definedEndDate } =
    definedRange || {};
  if (startDate && endDate && definedStartDate && definedEndDate) {
    return (
      startDate.toDateString() === definedStartDate.toDateString() &&
      endDate.toDateString() === definedEndDate.toDateString()
    );
  } else {
    return false;
  }
};

const staticRangesDefinations = [
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfThisMonth,
      endDate: today,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last 3 Months",
    range: () => ({
      startDate: defineds.startOfLast3Month,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last 6 Months",
    range: () => ({
      startDate: defineds.startOfLast6Month,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last 12 Months",
    range: () => ({
      startDate: defineds.startOfLast12Month,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last Financial Year",
    range: () => ({
      startDate: defineds.startOfLastFinancialYear,
      endDate: defineds.endOfLastFinancialYear,
    }),
  },
  {
    label: "Current Financial Year",
    range: () => ({
      startDate: defineds.startOfFinancialYear,
      endDate: today,
    }),
  },
];

const staticRanges = createStaticRanges(
  staticRangesDefinations.map((item) => ({
    ...item,
    isSelected: isSelectedHandler(item.range()),
  }))
);

const DatePickerPopover = ({
  dateSelection,
  setDateSelection,
  anchorEl,
  setAnchorEl,
  onSavedDateSelectionChange,
  doNotSaveDate = false,
}: DatePickerPopoverProps) => {
  const [savedDateSelection, setSavedDateSelection] =
    useLocalStorage<Range | null>("dateSelection", null);
  const theme = useTheme();

  const initialDateState = useMemo<Range>(() => {
    const DATE_TODAY = new Date();
    DATE_TODAY.setHours(0, 0, 0, 0);
    const INITIAL_END_DATE = new Date(DATE_TODAY);
    INITIAL_END_DATE.setHours(23, 59, 59, 999);
    const DATE_THREE_MONTHS = new Date(DATE_TODAY);
    DATE_THREE_MONTHS.setMonth(DATE_THREE_MONTHS.getMonth() - 3);
    return {
      startDate: doNotSaveDate ? DATE_TODAY : DATE_THREE_MONTHS,
      endDate: INITIAL_END_DATE,
      key: "selection",
    };
  }, [doNotSaveDate]);

  const [dateSelectionTemp, setDateSelectionTemp] =
    useState<Range>(initialDateState);

  const handleDateFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dateSelection && !doNotSaveDate) {
      if (JSON.stringify(dateSelection) === JSON.stringify(initialDateState)) {
        setSavedDateSelection(null);
        onSavedDateSelectionChange && onSavedDateSelectionChange(undefined);
      } else if (dateSelection) {
        setSavedDateSelection(dateSelection);
        onSavedDateSelectionChange && onSavedDateSelectionChange(dateSelection);
      }
    }
  }, [
    dateSelection,
    doNotSaveDate,
    initialDateState,
    onSavedDateSelectionChange,
    setSavedDateSelection,
  ]);

  useEffect(() => {
    if (savedDateSelection && !doNotSaveDate) {
      const { startDate, endDate } = savedDateSelection || {};
      const START_DATE = startDate && new Date(startDate);
      const END_DATE = endDate && new Date(endDate);
      setDateSelection((prevValue) => ({
        ...prevValue,
        startDate: START_DATE,
        endDate: END_DATE,
      }));
      setDateSelectionTemp((prevValue) => ({
        ...prevValue,
        startDate: START_DATE,
        endDate: END_DATE,
      }));
    } else {
      setDateSelection(initialDateState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on date apply function
  const onDateApply = () => {
    // Close the collapse
    handleDateFilterClose();
    const { endDate, startDate } = dateSelectionTemp || {};
    const START_DATE = startDate && new Date(startDate);
    const END_DATE = endDate && new Date(endDate);
    START_DATE?.setHours(0, 0, 0, 0);
    END_DATE?.setHours(23, 59, 59, 999);
    setDateSelection({ ...dateSelectionTemp, endDate: END_DATE });
  };

  const onDateClear = () => {
    setDateSelectionTemp(initialDateState);
    setDateSelection(initialDateState);
    handleDateFilterClose();
  };

  const handleDateChange = (ranges: RangeKeyDict) => {
    setDateSelectionTemp(ranges.selection);
  };

  return (
    <Popover
      id={"date-filter-popup"}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleDateFilterClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: "hidden",
          background: theme.palette.background.paper,
          color: theme.palette.getContrastText(theme.palette.background.paper),
          boxShadow: theme.shadows[1],
        },
      }}
    >
      <DateRangePickerStyled
        ranges={[dateSelectionTemp]}
        staticRanges={staticRanges}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        rangeColors={[theme.palette.primary.main]}
      />
      <Box
        sx={{
          px: 2,
          pb: 2,
          background: theme.palette.common.white,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IonButton
          fullWidth
          variant="contained"
          color="button"
          sx={{ fontSize: 12, mr: 1, height: 40 }}
          onClick={onDateApply}
        >
          Apply
        </IonButton>
        <IonButton
          fullWidth
          color="button"
          variant="outlined"
          sx={{ fontSize: 12, ml: 1, height: 40 }}
          onClick={onDateClear}
        >
          Clear
        </IonButton>
      </Box>
    </Popover>
  );
};

export default DatePickerPopover;
