import { ApiEndpoints, RESTServerRoute } from "../types/Server";
import { nexusAPI } from "@utils/APIInterceptor";
import {
  ActionResponse,
  PricingDefinitionDataResult,
} from "../types/DataResult";
import PricingDefinition, { PricingEntity } from "../types/Pricing";
import { getTenantID } from "@utils/authService";

async function getPricingDefinitions(payload: {
  Limit?: number;
  SortFields?: string;
  Skip?: number;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const tenantID = getTenantID();
    if (!tenantID) {
      throw new Error("tenantID not found");
    }
    const response = await nexusAPI(token).get<PricingDefinitionDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_PRICING_DEFINITIONS}`,
      {
        params: {
          EntityId: tenantID,
          EntityType: PricingEntity.TENANT,
          ...payload,
        },
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getPricingDefinition(payload: { id: string }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = payload || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).get<PricingDefinition>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_PRICING_DEFINITIONS}/${id}`
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function createPricingDefinition(body: PricingDefinition) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).post<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_PRICING_DEFINITIONS}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function putPricingDefinition(
  payload: { id: string },
  body: PricingDefinition
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = payload || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_PRICING_DEFINITIONS}/${id}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function deletePricingDefinitions(payload: { id: string }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = payload || {};
    if (!id) {
      throw new Error("Id not found");
    }
    const response = await nexusAPI(token).delete<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_PRICING_DEFINITIONS}/${id}`
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

export {
  getPricingDefinitions,
  getPricingDefinition,
  createPricingDefinition,
  putPricingDefinition,
  deletePricingDefinitions,
};
