import {
  Autocomplete,
  BaseTextFieldProps,
  Popper,
  TextField,
  autocompleteClasses,
  darken,
  styled,
  useTheme,
} from "@mui/material";
import { FieldProps } from "formik";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { getPricingDefinitions } from "@nexusAPI/pricing-definitions";
interface AutoCompleteProps extends Partial<FieldProps>, BaseTextFieldProps {
  index: number;
  initialTariffValue: { tariffName: string; tariffId: string };
  onChange?: ({ id, label }: { id: string; label: string }) => void;
}

const DEBOUNCE_TIME = 1000;

const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: theme.shadows[1],
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.dropdown,
    color: theme.palette.getContrastText(theme.palette.background.dropdown),
  },
  [`& .${autocompleteClasses.listbox}`]: {
    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: darken(theme.palette.background.dropdown, 0.1),
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: darken(theme.palette.background.dropdown, 0.1),
        },
    },
  },
}));

const TariffAutoComplete: FC<AutoCompleteProps> = (
  { field, form, label, initialTariffValue, onChange, disabled },
  ref
) => {
  const theme = useTheme();
  const { name = "" } = field || {};
  const { setFieldValue, touched, errors } = form || {};
  const [tariffValue, setTariffValue] = useState<{
    label: string;
    id: string;
  }>({
    label: initialTariffValue?.tariffName || "",
    id: initialTariffValue?.tariffId || "",
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const touch = touched && touched[name];
  const error = errors && errors[name];
  const errorMsg = touch && error;

  const handleAutoComplete = (value: { label: string; id: string } | null) => {
    setTariffValue({ label: value?.label || "", id: value?.id || "" });
    setFieldValue && setFieldValue(name, value?.id || null);
    onChange && value && onChange(value);
  };
  const [tariffOptions, setTariffOption] = useState<
    {
      label: string;
      id: string;
    }[]
  >([]);

  const [tariffSearchValue, setTariffSearchValue] = useState<string>("");
  const handleTariffListInput = (event: string) => {
    setTariffSearchValue(event);
  };
  const getTariffData = useCallback(
    async (filters: Parameters<typeof getPricingDefinitions>[0]) => {
      try {
        setLoading(true);
        const data = await getPricingDefinitions(filters);
        const { result } = data || {};
        setTariffOption([
          ...result.map((tariff) => ({
            label: tariff.name,
            id: tariff.id,
          })),
        ]);
      } catch {
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!open) {
      return undefined;
    }
    const delayInputTimeoutId = setTimeout(
      () => {
        getTariffData({
          Limit: 15,
          SortFields: "name",
          ...(tariffSearchValue ? { Search: tariffSearchValue } : {}),
        });
      },
      tariffSearchValue ? DEBOUNCE_TIME : 0
    );
    return () => clearTimeout(delayInputTimeoutId);
  }, [getTariffData, open, tariffSearchValue]);

  return (
    <>
      <Autocomplete
        loading={loading}
        disablePortal
        options={tariffOptions}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        PopperComponent={StyledAutocompletePopper}
        getOptionLabel={(option) => option.label}
        value={tariffValue}
        onChange={(event, value) => {
          handleAutoComplete(value);
        }}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
          setLoading(true);
        }}
        onClose={() => {
          setOpen(false);
          setTariffOption([]);
          setTariffSearchValue("");
        }}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            inputProps={{
              ...params.inputProps,
              sx: {
                borderRadius: 2,
                background: "none",
                boxShadow: theme.shadows[0],
              },
            }}
            helperText={errorMsg ? String(errorMsg) : ""}
            error={!!errorMsg}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleTariffListInput(event.target.value)
            }
            label={label}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default TariffAutoComplete;
