import React, { FC, useState } from "react";
import { FieldProps } from "formik";
import { Box, Typography, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "@assets/icons/upload-icon.svg";

interface ImageFieldType extends FieldProps {
  disabled?: boolean;
  variant?: "outlined" | "filled" | "standard";
  prefix?: string;
  suffix?: string;
  onChange?: (value: string | ArrayBuffer) => void;
  options: [];
  label?: string;
  placeholder?: string;
  minHeight?: number;
}

const StationPictureDropzone = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  background: theme.palette.grey[300],
  color: theme.palette.getContrastText(theme.palette.grey[300]),
  minHeight: 230,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  position: "relative",
  boxShadow: theme.shadows[1],
  "&:hover::after": {
    content: '"Upload photo"',
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.89)",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    color: "white",
    fontWeight: "bold",
    fontFamily: '"DM Sans", sans-serif',
    top: 0,
    left: 0,
  },
}));

const ImageField: FC<ImageFieldType> = ({
  field: { name, value },
  form: { setFieldValue, errors, touched, setFieldTouched, setFieldError },
  disabled = false,
  prefix,
  suffix,
  onChange = () => {},
  variant = "outlined",
  options,
  label,
  placeholder,
  minHeight,
  ...mImageFieldProps
}) => {
  const [error, setError] = useState<string>("");
  const err = (errors && errors[name]) || error;
  const touch = touched[name];
  const errorMsg = touch && err;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    multiple: false,
    maxSize: 150 * 1024,
    onDrop: (files) => {
      setFieldTouched(name, true);
      if (files.length) {
        var file = files[0];
        const reader = new FileReader();
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setFieldValue(name, reader.result);
          onChange(reader.result || "");
          img.src = reader.result as string;
        };
        img.onload = () => {
          const lowerBoundry = img.width * 4 - Math.floor(img.width * 4 * 0.05);
          const upperBoundry = img.width * 4 + Math.floor(img.width * 4 * 0.05);
          if (img.height * 3 < lowerBoundry || img.height * 3 > upperBoundry) {
            setError("Aspect ratio must be 4:3");
            setFieldTouched(name, true);
            setFieldValue(name, "");
          } else {
            setError("");
          }
        };
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onDropAccepted: (accepted) => {
      setError("");
    },
    onDropRejected: (rejected) => {
      setError(
        "Please make sure the image is less than 150kB & of type .PNG or .JPG"
      );
      setFieldValue(name, "");
      setFieldTouched(name, true);
    },
    ...mImageFieldProps,
  });

  return (
    <>
      <StationPictureDropzone {...getRootProps()} sx={{ minHeight }}>
        <input {...getInputProps()} />
        {value ? (
          <img
            alt="site"
            src={value}
            style={{
              height: "200px",
              width: "100%",
              objectFit: "contain",
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <UploadIcon fill={"currentColor"} width={16} height={22} />
            <Typography variant="subtitle2" mt={1}>
              {placeholder}
            </Typography>
          </Box>
        )}
      </StationPictureDropzone>
      <Typography variant="caption" color="error">
        {typeof errorMsg === "string" ? errorMsg : JSON.stringify(errorMsg)}
      </Typography>
    </>
  );
};

export default ImageField;
