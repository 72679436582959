import {
  Checkbox as MCheckbox,
  FormControl,
  FormControlLabel,
  BaseTextFieldProps,
} from "@mui/material";
import { FieldProps } from "formik";
import { FC, useEffect } from "react";
import { fieldNames } from "./fields";
interface CheckBoxProps extends FieldProps, BaseTextFieldProps {
  label: string;
  index: number;
  handleDedicatedAll?: (value: boolean) => void;
}

export const CheckBox: FC<CheckBoxProps> = ({
  form,
  field,
  label,
  index,
  handleDedicatedAll,
  disabled,
}) => {
  const { values, setFieldValue } = form;
  const { name, value } = field;

  const ischecked = values.stations[index]?.restriction === "DEDICATED";

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    checked ? setFieldValue(name, "DEDICATED") : setFieldValue(name, "SHARED");
  };

  useEffect(() => {
    const isAllChecked = values.stations.every(
      (item: { restriction: string }) => item.restriction === "DEDICATED"
    );
    handleDedicatedAll && handleDedicatedAll(isAllChecked);
    setFieldValue(
      fieldNames.defaultRestriction,
      isAllChecked ? "DEDICATED" : "SHARED"
    );
  }, [values.stations, handleDedicatedAll, setFieldValue]);

  return (
    <FormControl sx={{ alignSelf: "center" }}>
      <FormControlLabel
        label={label}
        value={value}
        control={
          <MCheckbox
            checked={ischecked}
            disabled={disabled}
            onChange={handleCheck}
          />
        }
        sx={{
          "& .MuiTypography-root": {
            fontSize: "14px",
          },
        }}
      ></FormControlLabel>
    </FormControl>
  );
};
