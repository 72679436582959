import {
  AlertColor,
  Avatar,
  Box,
  Divider,
  Grid,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";

import { useCallback, useEffect, useRef, useState } from "react";

import IonSnackbar from "@components/ionSnackbar";

import IonTable from "@components/ionTable";

import TableLoader from "@components/tableLoader";

import StatsCard from "@components/infoCards/statsCard";
import DatePicker, { DatePickerProps } from "@components/datePicker";
import SearchFilter from "@components/filters/searchFilter";
import { getFleets, getFleetsMetrics } from "@nexusAPI/fleet";
import FleetInfoModal from "./fleetInfoModal";

interface FleetListTableProps {}

const FLEET_LIST_LIMIT = 50;

const stringToColor = (string?: string) => {
  if (!string) {
    return "#000000";
  }
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const FleetListTable = (props: FleetListTableProps) => {
  const [fleetListLoading, setFleetListLoading] = useState<boolean>(true);
  const [fleetMetricsLoading, setFleetMetricsLoading] = useState<boolean>(true);

  const theme = useTheme();

  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity?: AlertColor;
  }>({
    message: "",
    severity: undefined,
  });
  const searchCloseRef = useRef<SVGSVGElement>(null);
  const [scrolledIndex, setScrolledIndex] = useState<number>(0);

  const [dateSelection, setDateSelection] =
    useState<DatePickerProps["dateSelection"]>();
  const [searchValue, setSearchValue] = useState<string>("");

  const [fleetListData, setFleetListData] = useState<
    Awaited<ReturnType<typeof getFleets>>["data"]
  >([]);
  const [fleetMetricsData, setFleetsMetricsData] =
    useState<Awaited<ReturnType<typeof getFleetsMetrics>>>();

  const [fleetInfoModalOpen, setFleetInfoModalOpen] = useState<number>(-1);

  const scrollParentRef = useRef<HTMLDivElement>(null);

  const getFleetMetricsData = useCallback(
    async (filters: Parameters<typeof getFleetsMetrics>[0]) => {
      try {
        setFleetMetricsLoading(true);
        const response = await getFleetsMetrics(filters);
        setFleetsMetricsData(response);
      } catch {
      } finally {
        setFleetMetricsLoading(false);
      }
    },
    []
  );

  const getFleetListData = useCallback(
    async (filters: Parameters<typeof getFleets>[0]) => {
      try {
        setFleetListLoading(true);
        const response = await getFleets(filters);
        const { data = [] } = response || {};
        setFleetListData((prevData) => [...prevData, ...data]);
      } catch {
      } finally {
        setFleetListLoading(false);
      }
    },
    []
  );

  const handleCloseModal =
    (fn: Function) =>
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        fn();
      }
    };

  // const handleUpdate = (value: boolean) => {
  //   setUpdated(value);
  // };
  const handleFleetInfoModalClose = () => {
    setFleetInfoModalOpen(-1);
  };

  const resetTable = useCallback(() => {
    setFleetListData([]);
    setScrolledIndex(0);
  }, []);

  const onContractUpdateCallback = () => {
    resetTable();
    if (searchCloseRef.current) {
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      searchCloseRef.current.dispatchEvent(clickEvt);
    } else {
      getFleetListData &&
        getFleetListData({
          onboarded: true,
          limit: FLEET_LIST_LIMIT,
          skip: 0,
        });
      const { endDate, startDate } = dateSelection || {};
      const currentFilters = {
        dateFrom: startDate?.toISOString(),
        dateTo: endDate?.toISOString(),
      };
      if (endDate && startDate) {
        getFleetMetricsData({ ...currentFilters });
      }
    }
  };

  useEffect(() => {
    const { endDate, startDate } = dateSelection || {};
    const currentFilters = {
      dateFrom: startDate?.toISOString(),
      dateTo: endDate?.toISOString(),
    };
    if (endDate && startDate) {
      getFleetMetricsData({ ...currentFilters });
    }
  }, [getFleetMetricsData, dateSelection]);

  useEffect(() => {
    const debounceCall = setTimeout(() => {
      getFleetListData &&
        getFleetListData({
          onboarded: true,
          limit: FLEET_LIST_LIMIT,
          skip: scrolledIndex,
          ...(searchValue !== "" && { search: searchValue }),
        });
    }, 500);
    return () => clearTimeout(debounceCall);
  }, [getFleetListData, scrolledIndex, searchValue]);

  const onSearchFilterHandle = useCallback(
    (value: string) => {
      if (searchValue === value) {
        return;
      }
      resetTable();
      setSearchValue(value);
    },
    [resetTable, searchValue]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Typography sx={{ mx: 2, mt: 2, fontSize: "24px", fontWeight: 700 }}>
        Fleet Summary
      </Typography>
      <Box
        sx={{
          mx: 2,
          mt: 2,
          display: "flex",
        }}
      >
        <Grid container item xs={12} spacing={2} sx={{ alignItems: "stretch" }}>
          <Grid item xs={12} md={3} sx={{ justifyContent: "space-evenly" }}>
            <Box
              sx={{
                p: 2,
                background: theme.palette.background.card,
                color: theme.palette.getContrastText(
                  theme.palette.background.card
                ),
                borderRadius: 2,
                boxShadow: theme.shadows[1],
                minHeight: 132,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <StatsCard
                label="Total Active Contracts"
                valueType="general"
                unit={"Contracts"}
                totalStatValue={
                  !fleetMetricsLoading
                    ? {
                        current:
                          fleetMetricsData?.total_active_contract_count || 0,
                      }
                    : {
                        current: 0,
                      }
                }
              />
              <Divider sx={{ borderColor: theme.palette.grey[200], my: 1 }} />
              <StatsCard
                label="Total Stations Assigned"
                valueType="general"
                unit={"Stations"}
                totalStatValue={
                  !fleetMetricsLoading
                    ? {
                        current: fleetMetricsData?.total_stations_count || 0,
                      }
                    : {
                        current: 0,
                      }
                }
              />
            </Box>
          </Grid>
          <Box
            sx={{
              background: theme.palette.background.card,
              color: theme.palette.getContrastText(
                theme.palette.background.card
              ),
              borderRadius: 2,
              boxShadow: theme.shadows[1],
              flex: 1,
              mt: 2,
              ml: 2,
              p: 2,
            }}
          >
            <Grid container item spacing={2}>
              <Grid item xs={12} md={9}>
                <Typography fontWeight={"500"} fontSize={20}>
                  Metrics
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                justifyContent={"flex-end"}
              >
                <DatePicker
                  dateSelection={dateSelection}
                  setDateSelection={setDateSelection}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    label="Total Sessions"
                    valueType="general"
                    unit="sessions"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              fleetMetricsData?.total_sessions_count || 0,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    label="Total Energy Consumed"
                    valueType="kWh"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              Math.round(
                                ((fleetMetricsData?.total_energy_consumed_wh ||
                                  0) /
                                  1000) *
                                  1000
                              ) / 1000,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <StatsCard
                    prefix={"₹"}
                    label="Revenue Collected"
                    valueType="general"
                    totalStatValue={
                      !fleetMetricsLoading
                        ? {
                            current:
                              Math.round(
                                ((fleetMetricsData?.total_revenue_collected_paise ||
                                  0) /
                                  100) *
                                  100
                              ) / 100,
                          }
                        : {
                            current: 0,
                          }
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box
        sx={{
          flex: 1,
          m: 2,
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.card,
          color: theme.palette.getContrastText(theme.palette.background.card),
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: theme.shadows[1],
        }}
      >
        <Box
          sx={{
            py: 2,
            px: 2,
            pt: 3,
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: theme.palette.background.tableHead,
            color: theme.palette.getContrastText(
              theme.palette.background.tableHead
            ),
          }}
        >
          <div>
            <Typography fontSize={"20px"} fontWeight={"bold"}>
              Onboarded Fleet List
            </Typography>
            <Typography mt={1} fontSize={"12px"} sx={{ opacity: 0.8 }}>
              All the fleets that you have a contract with will come here
            </Typography>
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              mx: 2,
            }}
          >
            <Box sx={{ display: "flex", my: 1, flexWrap: "wrap" }}>
              <Box sx={{ mx: 0.5, my: 0.5 }}>
                <SearchFilter
                  sx={{ width: "480px" }}
                  ref={searchCloseRef}
                  placeholder="Search by name"
                  onChange={onSearchFilterHandle}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ borderColor: theme.palette.grey[200] }} />
        <Box sx={{ flex: 1, position: "relative" }}>
          <div
            ref={scrollParentRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "auto",
            }}
          >
            <IonTable
              tableVirtuosoProps={{
                useWindowScroll: true,
                customScrollParent: scrollParentRef.current || undefined,
              }}
              tableProps={{
                stickyHeader: true,
                size: "small",
                sx: { tableLayout: "fixed" },
              }}
              columns={[
                { label: "Fleet Name", key: "name" },
                { label: "No. of Contracts", key: "contract_count" },
                {
                  label: (
                    <Typography fontWeight={"700"} fontSize={14} noWrap={true}>
                      Total Consumption{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[600],
                          fontSize: 14,
                        }}
                      >
                        /last 30 days
                      </Typography>
                    </Typography>
                  ),
                  key: "total_consumption_last_30_days_wh",
                },
                {
                  label: (
                    <Typography fontWeight={"700"} fontSize={14} noWrap={true}>
                      Total Revenue{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[600],
                          fontSize: 14,
                        }}
                      >
                        /last 30 days
                      </Typography>
                    </Typography>
                  ),
                  key: "total_revenue_last_30_days_paise",
                },
              ]}
              rows={fleetListData?.map((fleetListItem) => {
                const {
                  id,
                  name = "",
                  logo_url,
                  metrics,
                } = fleetListItem || {};
                const {
                  total_consumption_last_30_days_wh,
                  total_revenue_last_30_days_paise,
                  contract_count,
                } = metrics || {};

                return {
                  rowsx: {
                    padding: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      background: theme.palette.grey[200],
                      color: theme.palette.getContrastText(
                        theme.palette.grey[200]
                      ),
                    },
                  },
                  onClick: () => {
                    setFleetInfoModalOpen(id);
                  },
                  name: (
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={logo_url}
                        alt={name}
                        sx={{
                          bgcolor: stringToColor(name),
                          width: 32,
                          height: 32,
                          mr: 1,
                        }}
                      >
                        {name[0]}
                      </Avatar>
                      <Typography fontSize={14} noWrap={true}>
                        {name}
                      </Typography>
                    </Box>
                  ),
                  contract_count: (
                    <Typography fontSize={14} noWrap={true}>
                      {contract_count}
                    </Typography>
                  ),
                  total_revenue_last_30_days_paise: (
                    <Typography fontSize={14} noWrap={true}>
                      ₹
                      {Math.round(
                        (total_revenue_last_30_days_paise / 100) * 100
                      ) / 100}
                    </Typography>
                  ),
                  total_consumption_last_30_days_wh: (
                    <Typography fontSize={14} noWrap={true}>
                      {`${
                        Math.round(
                          (total_consumption_last_30_days_wh / 1000) * 1000
                        ) / 1000
                      } kWh`}
                    </Typography>
                  ),
                };
              })}
            />

            {fleetListLoading ? (
              <TableLoader rowCount={5} />
            ) : (
              !fleetListData?.length && (
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: 240,
                  }}
                >
                  <Typography>No records found.</Typography>
                </Box>
              )
            )}
          </div>
        </Box>

        <IonSnackbar
          open={Boolean(snackbar.message && snackbar.severity)}
          onClose={() => setSnackbar({ message: "", severity: undefined })}
          severity={snackbar.severity}
          message={snackbar.message}
        />
        <Modal
          open={fleetInfoModalOpen > -1}
          onClose={handleCloseModal(handleFleetInfoModalClose)}
          closeAfterTransition
        >
          <FleetInfoModal
            fleetId={fleetInfoModalOpen}
            handleClose={handleFleetInfoModalClose}
            handleUpdate={onContractUpdateCallback}
          />
        </Modal>
      </Box>
    </Box>
  );
};

export default FleetListTable;
