import TextField from "@components/form/textField";
import * as Yup from "yup";
import { CheckBox } from "./CheckBox";
import { Checkbox } from "@mui/material";
import StationAutoComplete from "./StationAutoComplete";
import TariffAutoComplete from "./TariffAutoComplete";
export enum fieldNames {
  name = "name",
  defaultTariffId = "defaultTariffId",
  defaultRestriction = "defaultRestriction",
  status = "status",
  stations = "stations",
  id = "id",
  tariffId = "tariffId",
  restriction = "restriction",
}

export interface FieldValueTypes {
  [fieldNames.name]: string;
  [fieldNames.defaultTariffId]: string;
  [fieldNames.defaultRestriction]: string;
  [fieldNames.status]: string;
  [fieldNames.stations]: {
    [fieldNames.id]: string;
    [fieldNames.tariffId]: string;
    [fieldNames.restriction]: string;
    stationName: string;
    tariffName: string;
  }[];
}

const commonProps = {
  color: "field",
  variant: "outlined",
};
export const fields = [
  {
    name: fieldNames.name,
    placeholder: "Contract Name",
    ...commonProps,
    label: "Contract Name",
    component: TextField,
  },
  {
    name: fieldNames.defaultTariffId,
    placeholder: "Default Tarrif",
    ...commonProps,
    label: "Default Tarrif",
    component: TariffAutoComplete,
  },
  {
    name: fieldNames.defaultRestriction,
    ...commonProps,
    label: "Dedicated",
    component: Checkbox,
  },
];
export const stationFields = [
  {
    name: fieldNames.id,
    placeholder: "Search & Select Station",
    ...commonProps,
    label: "Station",
    colCount: 4,
    component: StationAutoComplete,
  },
  {
    name: fieldNames.tariffId,
    placeholder: "Select Tariff",
    ...commonProps,
    label: "Tariff",
    colCount: 4,
    component: TariffAutoComplete,
  },
  {
    name: fieldNames.restriction,
    ...commonProps,
    label: "Dedicated",
    colCount: 2,
    component: CheckBox,
  },
];
export const initialValues: FieldValueTypes = {
  [fieldNames.name]: "",
  [fieldNames.defaultTariffId]: "",
  [fieldNames.defaultRestriction]: "DEDICATED",
  [fieldNames.status]: "ACTIVE",
  [fieldNames.stations]: [
    {
      [fieldNames.id]: "",
      [fieldNames.tariffId]: "",
      [fieldNames.restriction]: "DEDICATED",
      stationName: "",
      tariffName: "",
    },
  ],
};

export const validationSchema = Yup.object({
  [fieldNames.name]: Yup.string().required("Contract Name is required"),
  [fieldNames.defaultTariffId]: Yup.string()
    .required("Default Tarrif is required")
    .nullable(),
  [fieldNames.defaultRestriction]: Yup.string(),
  [fieldNames.status]: Yup.string(),
  [fieldNames.stations]: Yup.array().of(
    Yup.object({
      [fieldNames.id]: Yup.string(),
      [fieldNames.tariffId]: Yup.string().nullable(),
      [fieldNames.restriction]: Yup.string(),
    })
  ),
});
