import {
  Autocomplete,
  BaseTextFieldProps,
  Popper,
  TextField,
  autocompleteClasses,
  darken,
  styled,
  useTheme,
} from "@mui/material";
import { FieldProps } from "formik";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { getSites } from "@nexusAPI/sites";
import { FieldValueTypes } from "./fields";
interface AutoCompleteProps
  extends Partial<FieldProps<any, FieldValueTypes>>,
    BaseTextFieldProps {
  initialStationValue: { stationName: string; stationId: string };
  onChange?: ({ id, label }: { id: string; label: string }) => void;
}

const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: theme.shadows[1],
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.dropdown,
    color: theme.palette.getContrastText(theme.palette.background.dropdown),
  },
  [`& .${autocompleteClasses.listbox}`]: {
    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: darken(theme.palette.background.dropdown, 0.1),
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: darken(theme.palette.background.dropdown, 0.1),
        },
    },
  },
}));

const StationAutoComplete: FC<AutoCompleteProps> = (
  { field, form, label, initialStationValue, disabled, onChange },
  ref
) => {
  const theme = useTheme();
  const { name = "" } = field || {};
  const { setFieldValue, values } = form || {};
  const [stationValue, setStationValue] = useState<{
    label: string;
    id: string;
  }>({
    label: initialStationValue?.stationName || "",
    id: initialStationValue?.stationId || "",
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleAutoComplete = (value: { label: string; id: string } | null) => {
    setStationValue({ label: value?.label || "", id: value?.id || "" });
    setFieldValue && setFieldValue(name, value?.id || "");
    onChange && onChange(value || { label: "", id: "" });
  };
  const [stationOptions, setStationOption] = useState<
    {
      label: string;
      id: string;
    }[]
  >([]);

  const [stationSearchValue, setStationSearchValue] = useState<string>("");
  const handleStationListInput = (event: string) => {
    setStationSearchValue(event);
  };
  const getSitesData = useCallback(
    async (filters: Parameters<typeof getSites>[0]) => {
      try {
        const data = await getSites(filters);
        const { result } = data || {};
        let array: { label: string; id: string }[] = [];
        for (let i = 0; i < result.length; i++) {
          const { name, id } = result[i];
          if (values?.stations?.find((station) => station.id === id)) {
            continue;
          } else {
            array.push({ label: name, id });
          }
        }
        setStationOption(array);
      } catch {}
    },
    [values?.stations]
  );

  useEffect(() => {
    if (!open) {
      return;
    }
    const debounceCall = setTimeout(() => {
      getSitesData &&
        getSitesData({
          Issuer: true,
          Skip: 0,
          ...(stationSearchValue ? { Search: stationSearchValue } : {}),
          WithAvailableChargingStations: true,
          Limit: 15,
        });
    }, 500);
    return () => clearTimeout(debounceCall);
  }, [getSitesData, open, stationSearchValue]);

  return (
    <Autocomplete
      disabled={disabled}
      loading={loading}
      disablePortal
      options={stationOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      PopperComponent={StyledAutocompletePopper}
      getOptionLabel={(option) => option.label}
      value={stationValue}
      onChange={(event, value) => {
        handleAutoComplete(value);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
        setLoading(true);
      }}
      onClose={() => {
        setOpen(false);
        setStationOption([]);
        setStationSearchValue("");
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          inputProps={{
            ...params.inputProps,
            sx: {
              borderRadius: 2,
              background: theme.palette.common.white,
              boxShadow: theme.shadows[0],
            },
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleStationListInput(event.target.value)
          }
          label={label}
        />
      )}
    />
  );
};

export default StationAutoComplete;
