import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { BaseTextFieldProps, Typography } from "@mui/material";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "5px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface ActiveSwitchProps extends BaseTextFieldProps {
  handleSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  initialState?: boolean;
}

export default function AcitveSwitch({
  handleSwitch,
  initialState = true,
  disabled,
}: ActiveSwitchProps) {
  const [checked, setChecked] = React.useState(initialState);
  return (
    <FormGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          flexDirection: "row-reverse",
        },
      }}
    >
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={checked}
            onChange={(event) => {
              setChecked(event.target.checked);
              handleSwitch(event);
            }}
            disabled={disabled}
          />
        }
        label={
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            Active
          </Typography>
        }
      />
    </FormGroup>
  );
}
