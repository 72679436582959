import Table, { TableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { TableContainerProps } from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import { Box, TableSortLabel, Typography } from "@mui/material";
import { TableVirtuoso, TableVirtuosoProps } from "react-virtuoso";
import React from "react";
import { ReactComponent as InfoIcon } from "@assets/materialIcons/info.svg";
import IonageTooltip from "@components/ionTooltip";

interface IonTableProps {
  columns: Array<{
    label: React.ReactNode;
    key: string;
    sortKey?: string;
    width?: string | number;
    tooltip?: string;
  }>;
  rows?: Array<{ [key: string]: any }>;
  sort?: string;
  onSortChange?: (sort: string) => void;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
  tableVirtuosoProps?: TableVirtuosoProps<any, any>;
}

const ROW_HEIGHT = 48;

const HeaderContent = ({
  columns,
  sort,
  onSortChange,
}: Pick<IonTableProps, "columns" | "sort" | "onSortChange">) => {
  return (
    <TableRow
      sx={{
        "& th": {
          backgroundColor: "inherit",
          color: "inherit",
          borderColor: (theme) => theme.palette.grey[200],
          height: ROW_HEIGHT,
        },
      }}
    >
      {columns.map((column) => {
        const { key, label, sortKey, width, tooltip = "" } = column;
        return (
          <TableCell
            key={key}
            sx={{
              ...(width ? { width: width } : {}),
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {sortKey ? (
                <TableSortLabel
                  sx={{ display: "flex" }}
                  active={sort === sortKey || sort === `-${sortKey}`}
                  direction={sort && sort[0] === "-" ? "desc" : "asc"}
                  onClick={() => {
                    if (sort && sort[0] === "-") {
                      onSortChange && onSortChange(sortKey);
                    } else {
                      onSortChange && onSortChange(`-${sortKey}`);
                    }
                  }}
                >
                  {typeof label === "string" ? (
                    <Typography fontWeight={"700"} fontSize={14} noWrap={true}>
                      {label}
                    </Typography>
                  ) : (
                    label
                  )}
                </TableSortLabel>
              ) : typeof label === "string" ? (
                <Typography fontWeight={"700"} fontSize={14} noWrap={true}>
                  {label}
                </Typography>
              ) : (
                label
              )}
              {!!tooltip && (
                <IonageTooltip
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ],
                  }}
                  title={tooltip}
                  placement="bottom"
                >
                  <InfoIcon
                    fill="currentColor"
                    width={16}
                    height={16}
                    style={{ opacity: 0.8, marginLeft: "8px" }}
                  />
                </IonageTooltip>
              )}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const RowContent = ({
  columns,
  row,
}: {
  columns: IonTableProps["columns"];
  row: any;
}) => {
  const { cellsx } = row || {};
  return (
    <React.Fragment>
      {columns.map((column) => {
        const { key, width } = column;
        return (
          <TableCell
            sx={{
              backgroundColor: "inherit",
              color: "inherit",
              ...(width ? { width: width } : {}),
              ...cellsx,
            }}
            key={key}
          >
            {row[key]}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
};

const IonTable = ({
  columns = [],
  rows = [],
  sort,
  onSortChange,
  tableProps,
  tableVirtuosoProps,
}: IonTableProps) => {
  return (
    <TableVirtuoso
      {...tableVirtuosoProps}
      data={rows}
      components={{
        Table: (props) => <Table {...tableProps} {...props} />,
        TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
          <TableHead
            sx={(theme) => ({
              backgroundColor: theme.palette.background.tableHead,
              color: theme.palette.getContrastText(
                theme.palette.background.tableHead
              ),
            })}
            {...props}
            ref={ref}
          />
        )),
        TableRow: ({ item, ...props }) => {
          const { onClick, rowsx } = item || {};
          return (
            <TableRow
              sx={(theme) => ({
                "& td": {
                  borderColor: theme.palette.grey[200],
                  height: ROW_HEIGHT + 1,
                },
                // "&:last-child td, &:last-child th": { border: 0 },
                ...rowsx,
              })}
              onClick={onClick}
              {...props}
            />
          );
        },
        TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
          <TableBody {...props} ref={ref} />
        )),
        TableFoot: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
          <TableFooter {...props} ref={ref} />
        )),
      }}
      fixedHeaderContent={() => (
        <HeaderContent
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
        />
      )}
      itemContent={(_index, row) => {
        return <RowContent row={row} columns={columns} />;
      }}
    />
  );
};

export default IonTable;
